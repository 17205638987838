import {createSlice} from '@reduxjs/toolkit';

const updateChart = (state, value) => {
  let current = state?.chartData;
  let news = value;
  current.push(news);
  if (current?.length > 4) {
    current = current?.slice(-4);
  }
  return current;
};

const liveRateSlice = createSlice({
  name: 'liveRate',
  initialState: {
    current: [],
    prev: [],
    chartData: [],
    defult_metal: 'GOLD 995',
    isWeekend: false,
  },
  reducers: {
    rate: (state, action) => {
      state.prev = state.current;
      state.current = action.payload;
    },
    storeChart: (state, action) => {
      state.chartData = updateChart(state, action.payload);
    },
    changeDefultMetal: (state, action) => {
      state.defult_metal = action.payload;
    },
    checkWeekend: (state, action) => {
      state.isWeekend = action.payload;
    },
  },
});
export const {rate, storeChart, changeDefultMetal, checkWeekend} =
  liveRateSlice.actions;
export const selectLiveRate = (state) => state.liveRate;
export default liveRateSlice.reducer;
