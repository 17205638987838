import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
// Use localStorage instead of AsyncStorage for React JS
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import Slices from './slices'; // Your combined reducers

const persistConfig = {
  key: 'lsGold',
  storage, // Use localStorage for web
  whitelist: ['auth', 'countryCode', 'theme'], // State slices to persist
  blacklist: [], // State slices not to persist
};

const persistedReducer = persistReducer(persistConfig, Slices);

const middleWareConfigs = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
};

// Remove the TypeScript "any" type annotations for plain JavaScript
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(middleWareConfigs),
});

// Create the persisted store
const PersistedStore = persistStore(store);

export { PersistedStore, store };
