import React from "react";
import "./style.scss";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";


const Home = () => {
  const liveRate = useSelector((state) => state.liveRate.current);
  const weekend = useSelector((state) => state.liveRate.isWeekend);
  const prevLiveRate = useSelector((state) => state.liveRate.prev);
  const current = useSelector((state) => state.countryCode.current);

  const gold999Index = liveRate.findIndex((item) => item.metal === "GOLD 9999");
  const gold995Index = liveRate.findIndex((item) => item.metal === "GOLD 995");
  console.log(gold999Index);
  console.log(gold995Index);

  const metalNameConversion = (data) => {
    switch (data) {
      case "GOLD 995":
        return "gold_995";
        break;
      case "GOLD 9999":
        return "gold_9999";
        break;
      case "SILVER 999":
        return "silver_999";
        break;
      default:
        return "";
    }
  };
  const getTextColor = (currentPrice, prevPrice, colorProp) => {
    if (currentPrice > prevPrice) {
      return "green";
    } else if (currentPrice < prevPrice) {
      return "red";
    } else {
      if (colorProp !== "light") {
        return "#fff";
      } else {
        return "#f1f1f1";
      }
    }
  };
  const silverIndex = liveRate.findIndex((item) => item.metal === "SILVER 999");

  const calculateOunceRate = (gramRate) => {
    let result = gramRate?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return result || "0.00";
  };

  const calculateLiveRate = (type, metalName, weightIn) => {
    const findLiverate = liveRate.find(
      (item) => metalNameConversion(item.metal) === metalName
    );

    if (!findLiverate || !current.exchange_rate) {
      return "0.00";
    }

    let rate =
      (type === "buy"
        ? findLiverate.totalBuyAmount
        : findLiverate.totalSellAmount) * Number(current.exchange_rate);

    if (type === "buy" && weightIn) {
      rate *= Number(weightIn);
    }

    rate = Number(rate.toFixed(2));

    if (isNaN(rate)) {
      return "0.00";
    }

    return rate.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <div className="body">
      {/* <Container > */}
      <Row className=" g-3">
        <Col xs="12" sm="12" md="12" lg="6">
          <div className="rate-table">
            <div className="heading">
              <div className="heading-box1">RATE</div>
              <div className="heading-main">METALS</div>
              <div className="heading-box1">SELL</div>
            </div>
            <br />
            <div className="main-table">
              <div className="table-body">
                <div className="table-box1">GOLD 9999 </div>
                <div className="table-box2">1 GM</div>
                <div className="table-box3">AED</div>
                <div
                  className="table-box4"
                  style={{
                    color: getTextColor(
                      liveRate[gold999Index]?.totalBuyAmount,
                      prevLiveRate[gold999Index]?.totalBuyAmount
                    ),
                  }}
                >
                  {calculateLiveRate("buy", "gold_9999")}
                </div>
              </div>
              <div className="table-body">
                <div className="table-box1">GOLD 9999</div>
                <div className="table-box2">1 KG</div>
                <div className="table-box3">AED</div>
                <div
                  className="table-box4"
                  style={{
                    color: getTextColor(
                      liveRate[gold999Index]?.totalBuyAmount,
                      prevLiveRate[gold999Index]?.totalBuyAmount
                    ),
                  }}
                >
                  {" "}
                  {calculateLiveRate("buy", "gold_9999", 1000)}
                </div>
              </div>
              <div className="table-body">
                <div className="table-box1">GOLD 995</div>
                <div className="table-box2">1 GM</div>
                <div className="table-box3">AED</div>
                <div
                  className="table-box4"
                  style={{
                    color: getTextColor(
                      liveRate[gold995Index]?.totalBuyAmount,
                      prevLiveRate[gold995Index]?.totalBuyAmount
                    ),
                  }}
                >
                  {calculateLiveRate("buy", "gold_995")}
                </div>
              </div>
              <div className="table-body">
                <div className="table-box1">GOLD 9999</div>
                <div className="table-box2">1 KG</div>
                <div className="table-box3">AED</div>
                <div
                  className="table-box4"
                  style={{
                    color: getTextColor(
                      liveRate[gold995Index]?.totalBuyAmount,
                      prevLiveRate[gold995Index]?.totalBuyAmount
                    ),
                  }}
                >
                  {calculateLiveRate("buy", "gold_995", 1000)}
                </div>
              </div>
              <div className="table-body">
                <div className="table-box1">SILVER 999</div>
                <div className="table-box2">1 GM</div>
                <div className="table-box3">AED</div>
                <div
                  className="table-box4"
                  style={{
                    color: getTextColor(
                      liveRate[silverIndex]?.totalBuyAmount,
                      prevLiveRate[silverIndex]?.totalBuyAmount
                    ),
                  }}
                >
                  {calculateLiveRate("buy", "silver_999")}
                </div>
              </div>
            </div>
          </div>
        </Col>

        {/* rate table two */}

        <Col xs="12" sm="12" md="12" lg="6">
          <div className="rate-table2">
            <div className="heading">
              <div className="heading-box1">PRODUCT</div>
              <div className="heading-box1">BID(SELL)</div>
              <div className="heading-box1">ASK(BUY)</div>
            </div>
            <br />

            <div className="section2-table">
              <div className="section-box">
                <b> GOLD OZ</b>
              </div>

              <div className="section-box2">
                <div
                  className="bid-sell-box"
                  style={{
                    color: getTextColor(
                      liveRate[gold999Index]?.ounceUsdBid,
                      prevLiveRate[gold999Index]?.ounceUsdBid,
                      "light"
                    ),
                  }}
                >
                  {calculateOunceRate(liveRate[gold999Index]?.ounceUsdBid)}{" "}
                </div>
                {/* <div className="bid-sell-box">LOW </div>
                <div className="bid-sell-box">
                  {calculateOunceRate(liveRate[gold999Index]?.lowPrice)}
                </div> */}
              </div>
              <div className="section-box2">
                <div
                  className="bid-sell-box"
                  style={{
                    color: getTextColor(
                      liveRate[silverIndex]?.ounceUsdAsk,
                      prevLiveRate[silverIndex]?.ounceUsdAsk,
                      "light"
                    ),
                  }}
                >
                  {calculateOunceRate(liveRate[gold999Index]?.ounceUsdAsk)}
                </div>
                {/* <div className="bid-sell-box">LOW</div>
                <div className="bid-sell-box">
                  {calculateOunceRate(liveRate[gold999Index]?.highPrice)}
                </div> */}
              </div>
            </div>
            <br />
            <div className="section2-table">
              <div className="section-box">
                <b>SILVER OZ</b>{" "}
              </div>

              <div className="section-box2">
                <div
                  className="bid-sell-box"
                  style={{
                    color: getTextColor(
                      liveRate[silverIndex]?.ounceUsdBid,
                      prevLiveRate[silverIndex]?.ounceUsdBid,
                      "light"
                    ),
                  }}
                >
                  {calculateOunceRate(liveRate[silverIndex]?.ounceUsdBid)}
                </div>
                {/* <div className="bid-sell-box">LOW </div>
                <div className="bid-sell-box">
                  {calculateOunceRate(liveRate[silverIndex]?.lowPrice)}
                </div> */}
              </div>
              <div className="section-box2">
                <div
                  className="bid-sell-box"
                  style={{
                    color: getTextColor(
                      liveRate[silverIndex]?.ounceUsdAsk,
                      prevLiveRate[silverIndex]?.ounceUsdAsk,
                      "light"
                    ),
                  }}
                >
                  {calculateOunceRate(liveRate[silverIndex]?.ounceUsdAsk)}
                </div>
                {/* <div className="bid-sell-box">HIGH </div>
                <div className="bid-sell-box">
                  {calculateOunceRate(liveRate[silverIndex]?.highPrice)}
                </div> */}
              </div>
            </div>
            <br />
            <div className="gold-image"></div>
          </div>
        </Col>
      </Row>

      {/* </Container> */}
    </div>
  );
};

export default Home;
